<style lang="scss" scoped></style>

<template>
    <div class="page-merchant-position">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button type="primary" size="medium" icon="el-icon-plus" @click="_edit()">新增</el-button>
                </div>
                <div class="fr">
                    <el-select class="mrgr5 mrgb5" v-model="filter.merchantId" filterable remote reserve-keyword placeholder="选择商家" :remote-method="renderMerchantOptions"
                        clearable @change="_search()" size="medium">
                        <el-option v-for="item in merchantItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <el-button type="primary" @click="_search()" size="medium">查询</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-table :data="tableData.items" border fit highlight-current-row :empty-text="emptyText">
                <el-table-column label="商家ID" prop="merchantId" width="120"></el-table-column>
                <el-table-column label="投放位置ID" prop="id" width="120"></el-table-column>
                <el-table-column label="投放位置描述" prop="position"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_edit(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--编辑投放位置-->
        <edit :ref="refEdit" @refreshData="_refreshData"></edit>
    </div>
</template>

<script>
import edit from './components/PositionEdit.vue'
import * as funMerchant from "@/api/merchant/index"
export default {
    name: "pageMerchantPosition",
    components: { edit },
    data() {
        return {
            refEdit: "refPositionToPositionEdit",
            emptyText: "",
            tableData: {
                items: [],
                totalCount: 0
            },
            filter: {
                merchantId: null,
                keywords: "",
                page: 1,
                size: window.$common.appPageSize
            },
            merchantItems: []
        }
    },
    activated() {
        this.renderMerchantOptions("");
    },
    created() {
        this.emptyText = "~请先输入查询的商家~";
        this.renderMerchantOptions("");
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        getDataList() {
            if (!this.filter.merchantId) {
                this.$message.warning("请输入查询的商家!")
                return false
            }
            this.emptyText = "~暂无数据~"
            window.$common.fullLoading()
            funMerchant.PositionList(this.filter).then(res => {
                window.$common.closeFullLoading()
                this.tableData = res
            })
        },
        _edit(row) {
            this.$refs[this.refEdit].open(row)
        },
        async _delete(row) {
            this.confirm(`确认要删除商户【${row.merchantId}】的投放位置【${row.position}】吗？`).then(() => {
                window.$common.fullLoading()
                funMerchant.PositionDeletes({ id: row.id }).then(res => {
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        _refreshData(data) {
            if (data && data.id) {
                this.filter.merchantId = data.merchantId
                this.renderMerchantOptions("")
                this._search()
            }
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        }
    }
}
</script>