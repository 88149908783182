<style lang="scss" scoped>

</style>

<template>
    <div>
        <el-dialog :title="title + '设备投放位置'" :visible.sync="showDialog">
            <el-form :model="formData" :ref="formRefName" :rules="rules" label-width="120px">
                <el-form-item label="选择商家" prop="merchantId">
                    <el-select
                        v-model="formData.merchantId"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请选择商家"
                        :remote-method="renderMerchantOptions">
                        <el-option
                            v-for="item in merchantItems"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="投放位置" prop="position">
                    <el-input v-model="formData.position" clearable placeholder="请输入商家投放位置（如：第一住院部5楼）"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="_cancel()">取 消</el-button>
                <el-button type="primary" @click="_submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as funMerchant from "@/api/merchant/index"
export default {
    name: "",
    data() {
        return {
            formRefName: "refPositionEditForm",
            title: "",
            showDialog: false,
            formData: {
                id: 0,
                merchantId: null,
                position: ""
            },
            merchantItems: [],
            rules: {
                merchantId: [{ required: true, message: "请选择商家", trigger: ["change", "blur"] }],
                position: [{ required: true, message: "请输入商家投放位置", trigger: "blur" }]
            }
        }
    },
    methods: {
        open(row) {
            this.title = "新增"
            if (row && row.id) {
                this.title = "修改"
                this.formData = row
            } else {
                this.formData = {
                    id: 0,
                    merchantId: null,
                    position: ""
                }
            }
            this.renderMerchantOptions("")
            this.showDialog = true
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        },
        _submit() {
            this.$refs[this.formRefName].validate(async (valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.formData))
                    window.$common.fullLoading()
                    if (this.formData.id) {
                        funMerchant.PositionUpdate(commitData).then(res => {
                            this.$emit("refreshData", res)
                            this._cancel()
                        })
                    } else {
                        funMerchant.PositionCreate(commitData).then(res => {
                            this.$emit("refreshData", res)
                            this._cancel()
                        })
                    }
                }
            })
        },
        _cancel() {
            window.$common.closeFullLoading()
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        }
    }
}
</script>